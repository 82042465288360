exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-buying-agent-js": () => import("./../../../src/pages/buying-agent.js" /* webpackChunkName: "component---src-pages-buying-agent-js" */),
  "component---src-pages-company-details-js": () => import("./../../../src/pages/company-details.js" /* webpackChunkName: "component---src-pages-company-details-js" */),
  "component---src-pages-complaints-procedure-js": () => import("./../../../src/pages/complaints-procedure.js" /* webpackChunkName: "component---src-pages-complaints-procedure-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-holiday-home-acquisition-cornwall-js": () => import("./../../../src/pages/holiday-home-acquisition-cornwall.js" /* webpackChunkName: "component---src-pages-holiday-home-acquisition-cornwall-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interior-styling-js": () => import("./../../../src/pages/interior-styling.js" /* webpackChunkName: "component---src-pages-interior-styling-js" */),
  "component---src-pages-pre-purchase-js": () => import("./../../../src/pages/pre-purchase.js" /* webpackChunkName: "component---src-pages-pre-purchase-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-property-management-js": () => import("./../../../src/pages/property-management.js" /* webpackChunkName: "component---src-pages-property-management-js" */),
  "component---src-pages-statutory-register-js": () => import("./../../../src/pages/statutory-register.js" /* webpackChunkName: "component---src-pages-statutory-register-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

